import {BACKEND_API_URL} from '../config'

// Check if you are logged in
export const cancelSubscription = async(subscriptionIdCode) => {
    const result = await new Promise((resolve, reject) => {
        window
        .fetch(BACKEND_API_URL+"/misc/subscription/cancel", {
            method: "POST",
            headers: {
            "Content-Type": "application/json"
            },
            body: JSON.stringify({
                stripeSubscriptionId : subscriptionIdCode
            })
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            // console.log('Received from server: ',data)
            resolve(data);
        })
        .catch(err => {
            resolve({
                error: true,
                message: err
            })
        });
    });

    //console.log('Fetch node details result: ',result);
    return result;
}
