import React, {Component} from 'react';


import Form from '../components/home/Form';
import Video from '../components/home/Video'
import AppStoreBadge from '../components/home/App-store-badge'
import Header from '../components/header/Header';
import ButtonDark from '../components/ButtonLight';

import '../App.css';

class Home extends Component {
  constructor(){
    super()
    this.state = {
      videoURL : "testurl"
    }
  }

  goToSubscribe = () => {
    window.location.replace('/pricing');
  }


  render(){
    return (
      <div className="App">
        <div style={{position: 'absolute', width: '100%', height: 100, display: 'flex', justifyContent: 'center'}}>
          <Header/>
        </div>
        <div className="App-bottom" style={{justifyContent: 'flex-end'}}>
          <div style={{flex: 4, width: '100%', minHeight: '400px'}}/>
          <div className="App-text">
              <div className="title">
                Supercharge your mac.
                
              </div>
              <div className="title">
              ChatGPT and Dall-E.
              </div>
              <br/>
              <ButtonDark title="Start using it now" onClickAction={()=>this.goToSubscribe()} shadows={false}/>
          </div>
          <div className="App-subscribe">
            <Form/>
            <br/>
            <a href="https://www.producthunt.com/posts/chai-3?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-chai&#0045;3" target="_blank">
              <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=385956&theme=light&period=weekly&topic_id=267" alt="Chai - Supercharge&#0032;your&#0032;Mac&#0032;with&#0032;ChatGPT&#0032;&#0038;&#0032;DALLE | Product Hunt" style={{width: '180px', height: '44px'}} width="200" height="54" /></a>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
