import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";

import {cancelSubscription} from '../logic/misc/subscription-cancel'

const SubscriptionCancel = (props) => {

    ///
    /// PROPS AND PARAMS
    ///

    ///The Hook way of getting address params (old props.match.params.xxx)
    let { b64id } = useParams()
    let [confirmed, setConfimed] = useState(false)
    let [busy, setBusy] = useState(false);                                                                                      //to prevent double requests

    ///
    /// METHODS
    ///


    // Force a refresh of userData (for change of plans, etc). Passed down on props
    const cancelSubscriptionAction = async() =>
    {
        if(busy)
            return;
        setBusy(true)   

        const checkResult = await cancelSubscription(b64id)

        if(checkResult.error)
        {
            console.warn('Error deleting subscription: ',checkResult.message)
            setConfimed(false)
            setBusy(false)
        }else{
            setConfimed(true)
            setBusy(false)
        }
    }

    ///
    /// UseEffects
    ///

    ///Use effect no se para que era. Un metodo para ser llamado on load?
    useEffect(() => {
        ///1.log
        //console.log(`Wants to cancel id ${b64id}`);
        ///2. check credentials
        //cancelSubscription()
    },[]);

    
    ///
    /// RENDER
    ///

    if(busy)
    {
        return (
            <div style={style.container}>
                Please wait...
            </div>
        );
    }

    if(!confirmed)
    {
        return (
            <div style={style.container}>
                Do you really want to cancel yout <b>Chai</b> subscription? It will not renew.<div style={{cursor: 'pointer', fontWeight: 'bold'}} onClick={()=>{cancelSubscriptionAction()}}>if so, click here</div>
            </div>
        );
    }

    //Render
    return (
        <div style={style.container}>
            Your Subscription will not be renewed anymore. Contact <b><u>mail@chaibar.ai</u></b> for more details.
        </div>
    );
    
}

const style = {
    container: {
        /* Only to keep stuff centered. Could be done on a parent level for all Pages */
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        color: '#fff'
    }
    };

export default SubscriptionCancel;
    