import React, {useState, useEffect} from 'react';
import Header from '../components/header/Header';


const Privacy = (props) => {

    ///
    /// PROPS AND PARAMS
    ///

    ///The Hook way of getting address params (old props.match.params.xxx)
    let [confirmed, setConfimed] = useState(false)
    let [busy, setBusy] = useState(false);                                                                                      //to prevent double requests

    ///
    /// METHODS
    ///


    ///
    /// UseEffects
    ///

    ///Use effect no se para que era. Un metodo para ser llamado on load?
    useEffect(() => {
        ///1.log
        //console.log(`Wants to cancel id ${b64id}`);
    },[]);

    
    ///
    /// RENDER
    ///
    //Render
    return (
        <div className="AppClean" style={{minHeight: '100%'}}>
          <div style={{position: 'absolute', width: '100%', height: 100, display: 'flex', justifyContent: 'center'}}>
            <Header/>
          </div>
          <div style={{height: 200, width: '100%'}}/>
          <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '70%', maxWidth: '900px', borderRadius: 20, background: 'white', padding: 40, paddingTop: 50, paddingBottom: 50, textAlign: 'left', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif'}}>
            <div>
            <h2>Privacy Policy</h2>
            <p>Chai (or chaibar.ai) is a digital service provided by Nada Studio SL.</p>
            <p>At Nada Studio SL, we take your privacy very seriously. When you use "Chai", we ensure that we don't keep or save any of your queries (prompts) or responses. However, the service we provide depends on OpenAI's ChatGPT API, and we have to send your queries to their servers for Chai to work. </p>
            <p>We also want you to know that we won't sell or transfer any of our clients' data to third-party companies or organizations. Your data is safe with us.</p>
            <p>If you have any questions or concerns about our privacy policy or how we protect your data, please don't hesitate to contact us at hola@nada.studio</p>
            </div>
            </div>
          </div>
        </div>
      );
    
}

const style = {
    container: {
        /* Only to keep stuff centered. Could be done on a parent level for all Pages */
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        color: '#fff'
    }
    };

export default Privacy
    