import React, {Component} from 'react'

class Form extends Component{
    constructor(props){
        super(props)
        this.state = {
            mail: '',
        }
    }
    _onChange = (t) => {
        this.setState({
            mail: t.target.value,
        })
    }
    _onKeyPress = (e) => {
        if(e.key === 'Enter'){
            //do something
            console.log('ENTER');
        }
    }
    render(){
        if(this.state===undefined || this.state.mail===undefined) return <div/>
        return(
            <div style={{width: '90%', height: '80px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <form action="https://me.us21.list-manage.com/subscribe/post?u=25b8da525acb8e0a7c8e66544&amp;id=3486ff22bd&amp;f_id=0097afe1f0" method="POST" target="_blank" noValidate>
                <input type="hidden" name="u" value="25b8da525acb8e0a7c8e66544"/>
                <input type="hidden" name="id" value="3486ff22bd"/>
                <input
                    type="email" 
                    name="EMAIL" 
                    id="mce-EMAIL"
                    style={this._style.formInput}
                    onChange={(t)=>this._onChange(t)}
                    onKeyPress={(e)=>this._onKeyPress(e)}
                    value={this.state.mail}
                    placeholder="Send me email updates"
                    autoCapitalize="off" 
                    autoCorrect="off"
                    />
               
                <button type="submit" style={this._style.formInputButton}>Join</button>
                </form>
            </div>
        )
    }

    _style = ({
        formInput: {
            fontFamily: 'Museo',
            width: '200px',
            height: 40,
            backgroundColor: '#333333',
            color: '#fff',
            border: 0,
            margin: 4,
            padding: 0,
            paddingLeft: 12,
            paddingRight: 12,
            fontSize: '16px',
            textAlign: 'center',
            borderRadius: '5px'
        },
        formInputButton: {
            cursor: 'pointer',
            fontFamily: 'Museo',
            height: 38,
            margin: 4,
            backgroundColor: '#fff',
            color: '#333333',
            border: 0,
            padding: 0,
            paddingLeft: 12,
            paddingRight: 12,
            fontSize: '15px',
            textAlign: 'center',
            borderRadius: '5px',
        }
    })
}

export default Form