import React, {Component} from 'react'

class ButtonLight extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
            hover: false,
        };
    }
    render(){
        return (
            <div style={this.state.hover ? this.style(this.props).buttoHover : this.style(this.props).button} onClick={this.props.disabledButton === true ? ()=>{/*console.log('disabled')*/} : this.props.onClickAction} onMouseEnter={()=>{this.setState({hover: true})}} onMouseLeave={()=>{this.setState({hover: false})}}>
                {this.props.title}
            </div>
        );
    }

    //We make it as a return, so it's not hardcoded with props the first time its loaded, but dynamically due to subProps given from the render function
    style = (subProps) => {
        return {
        button:{	
            cursor: 'pointer',
            boxSizing: 'border-box',
            height: '44px',
            opacity: subProps.disabledButton === true ? '0.3' : '1',
            fontSize: '16px',
            width: '100%',
            backgroundColor: '#f9f9f9',
            color: '#373737',
            fontWeight: '500',
            overflow: "hidden",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            borderRadius:10,
            borderWidth: 0,
            WebkitBoxShadow: subProps.shadows===false ?  '0px 0px 0px 0px rgba(0,0,0,0.00)' : '4px 4px 8px 0px rgba(0,0,0,0.07),-6px -6px 8px 0px #fff',
            boxShadow: subProps.shadows===false ?  '0px 0px 0px 0px rgba(0,0,0,0.00)' : '4px 4px 8px 0px rgba(0,0,0,0.07),-6px -6px 8px 0px #fff',
            WebkitTransition: 'all 0.5s ease',
            transition: 'all 0.5s ease',
            
          },
          buttoHover:{	
            boxSizing: 'border-box',
            cursor: 'pointer',
            height: '44px',
            opacity: subProps.disabledButton === true ? '0.3' : '1',
            fontSize: '16px',
            width: '100%',
            backgroundColor: '#f9f9f9',
            color: '#373737',
            fontWeight: '500',
            overflow: "hidden",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            borderRadius:10,
            borderWidth: 0,
            WebkitBoxShadow: subProps.shadows===false ?  '0px 0px 0px 0px rgba(0,0,0,0.00)' : '4px 4px 8px 0px rgba(0,0,0,0.07),-6px -6px 8px 0px #fff',
            boxShadow: subProps.shadows===false ?  '0px 0px 0px 0px rgba(0,0,0,0.00)' : '4px 4px 8px 0px rgba(0,0,0,0.07),-6px -6px 8px 0px #fff',
            WebkitTransition: 'all 0.5s ease',
            transition: 'all 0.5s ease',
          }
        }
    }
}

export default ButtonLight