import React, {Component} from 'react'

class AppStoreBadge extends Component{
    constructor(props){
        super(props)
    }

    _onPress = () => {
        var url = 'https://apps.apple.com/us/app/1sland/id1492928510';
        var win = window.open(url, '_blank');
        win.focus();
    }
    render(){
        return(
            <div style={{width: '80%', margin: '00px'}}>
                <div style={this._style.badge} onClick={()=>this._onPress()}/>
            </div>
        )
    }

    _style = ({
        badge: {
            display: 'inline-block',
            overflow: 'hidden',
            cursor: 'pointer',
            background: 'url(https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg) no-repeat',
            width: '135px',
            height: '40px',
            backgroundSize: 'contain',
        }
    })
}

export default AppStoreBadge