import React, {useState, useEffect} from 'react';


const DownloadMac = (props) => {

    ///
    /// PROPS AND PARAMS
    ///

    ///The Hook way of getting address params (old props.match.params.xxx)
    let [confirmed, setConfimed] = useState(false)
    let [busy, setBusy] = useState(false);                                                                                      //to prevent double requests

    ///
    /// METHODS
    ///


    ///
    /// UseEffects
    ///

    ///Use effect no se para que era. Un metodo para ser llamado on load?
    useEffect(() => {
        ///1.log
        //console.log(`Wants to cancel id ${b64id}`);
        ///2. check credentials
        window.location.replace('https://cdn.1slandgame.com/chaibar/chai-mac-latest.dmg');
    },[]);

    
    ///
    /// RENDER
    ///
    //Render
    return (
        <div style={style.container}>
            Redirecting to download...
        </div>
    );
    
}

const style = {
    container: {
        /* Only to keep stuff centered. Could be done on a parent level for all Pages */
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        color: '#fff'
    }
    };

export default DownloadMac
    