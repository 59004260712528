import React, {useState, useEffect} from 'react';
import Header from '../components/header/Header';


const Pricing = (props) => {

    ///
    /// PROPS AND PARAMS
    ///

    ///The Hook way of getting address params (old props.match.params.xxx)
    let [confirmed, setConfimed] = useState(false)
    let [busy, setBusy] = useState(false);                                                                                      //to prevent double requests

    ///
    /// METHODS
    ///


    ///
    /// UseEffects
    ///

    ///Use effect no se para que era. Un metodo para ser llamado on load?
    useEffect(() => {
        ///1.log
        //console.log(`Wants to cancel id ${b64id}`);

        const script = document.createElement("script");

        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;

        document.body.appendChild(script);
    },[]);

    
    ///
    /// RENDER
    ///
    //Render
    return (
        <div className="AppClean" style={{minHeight: '100%'}}>
          <div style={{position: 'absolute', width: '100%', height: 100, display: 'flex', justifyContent: 'center'}}>
            <Header/>
          </div>
          <div style={{height: 200, width: '100%'}}/>
          <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '70%', maxWidth: '900px', borderRadius: 20, background: 'white', padding: 30, paddingTop: 50, paddingBottom: 30}}>
            <stripe-pricing-table pricing-table-id="prctbl_1MrPmoHy27fjXjMefxHyB2F0"
                publishable-key="pk_live_51MnqChHy27fjXjMeLcqekRrXmEfDGb4bKEPsh6a2VhhOlTocs5gg55FNmNNmlEp2nHGTJHVJE6s1RcjNvasFUXbN00BSe7FuLX">
                </stripe-pricing-table>
                <div style={{width: '100%', textAlign: 'left', color: '#999', fontWeight: 300, fontSize: '10pt', marginTop: '50px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif'}}>
                <p>You need a subscription to use Chai.<br/>Once subscribed, you can either download it from <a href="/download/mac" target="_blank">here</a> or compile your own binary from <a href="https://github.com/sergioabril/chai-mac/" target="_blank">GitHub</a>.</p>
                <br/>Usage policy:<br/>
                <p>For Basic plan, it refers to a maximum of ~1,250,000 tokens.<br/>
                For Pro plan, it refers to a maximum of either ~4,500,000 tokens, either 45 images, or a combination of both.</p>
                <p>Usage for ChatGPT is measured in Tokens, as per OpenAI®'s method. A token is equivalent to ~0.75 words. As for images, they are billed by units. We use OpenAI®'s pricing as a base for ours, applying a tight margin on top that accounts for our infrastructure cost.</p>
                <br/>Refunds policy:<br/>
                <p>Given the nature of our service, once the account is used, we can not offer refunds. However, you can cancel your subscription anytime, and it will expire at the end of the billing cycle.</p>
                </div>
            </div>
          </div>
        </div>
      );
    
}

const style = {
    container: {
        /* Only to keep stuff centered. Could be done on a parent level for all Pages */
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        color: '#fff'
    }
    };

export default Pricing
    