import React, {useState, useEffect} from 'react'

import '../../App.css';

const Header = () => {

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      });
    

    //
    //Render
    //
    return (
        <div style={{width: '100%', height: '100', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{flex: 2}}/>
            <a href="/" style={style.linkStyle}>Home</a>
            <div style={{flex: 1}}/>
            <a href="/pricing" style={style.linkStyle}>Pricing</a>
            <div style={{flex: windowSize[0] < 800 ? 0 : 1}}/>
            <a href="/download/mac" style={windowSize[0] < 800 ? style.hiddenStyle : style.linkStyle}>Download</a>
            <div style={{flex: 1}}/>
            <a href="/privacy" style={style.linkStyle}>Privacy</a>
            <div style={{flex: 1}}/>
            <a href="https://github.com/sergioabril/chai-mac/" style={windowSize[0] < 800 ? style.hiddenStyle : style.linkStyle} target="_blank">GitHub</a>
            <div style={{flex: windowSize[0] < 800 ? 0 : 1}}/>
            <a href="mailto:hola@nada.studio?subject=chai" style={style.linkStyle}>Contact</a>
            <div style={{flex: 2}}/>
        </div>
    );
}


const style = {
    headerSpace:{
        height: 40,
        width: '100vw',
    },
    header:{
        height: '80px',
        width: '100vw',
        color: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        backgroundColor: 'white'
    },
    centeredArea: {
        height: '80px',
        display: 'flex',
        width: '100%',
        flex: '1',
        maxWidth: '800px',
    },
    leftPart: {
        flex: '1',
        display: 'flex',
        width: '100%',
        justifyContent:'flex-start',
        alignItems: 'center'
    },
    rightPart: {
        flex: '1',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    linkStyle: {
        color: 'white',
        textDecoration: 'none',
        fontFamily: 'Museo',
        fontWeight: 500,
    },
    hiddenStyle : {
        display: 'none'
    }
  };

export default Header